import * as React from "react"
import { Link } from "gatsby"
import { v4 as uuidv4 } from "uuid"
import { MediaCard } from "../components/resources"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { useState } from "react"
import {
  Form,
  FormGroup,
  FormControl,
  Button,
} from "react-bootstrap"
import { errorFindSTA } from "../functions/errorFind"
import Dar from "@mui/icons-material/DoubleArrow"
const breadData = [
  {
    id: 1,
    ink: "/",
    label: "HOME",
  },
  {
    id: 3,
    ink: "/partners/",
    label: "Partners",
  },
]

const PartnersChan = () => {
  const [errors, setErrors] = useState({})
  const [subscribe, setSubscribe] = useState(false)
  const [fields, setFields] = useState({
    firstName: "",
    lastName: "",
    job: "",
    company: "",
    email: "",
    subscribe: "",
  })

  const mediaCardR1 = [
    {
      rec: "../images/client-support/icon1.png",
      title: "No Race to Zero",
    },
    {
      rec: "../images/client-support/icon2.png",
      title: "Track record of low attrition",
    },
    {
      rec: "../images/client-support/icon3.png",
      title: "Growth portfolio with pricing increases",
    },
  ]

  const mediaCardR2 = [
    {
      rec: "../images/client-support/icon4.png",
      title: "Short sale lifecycle: weeks not months",
    },
    {
      rec: "../images/client-support/icon5.png",
      title: "Fast Commission Payout",
    },
    {
      rec: "../images/client-support/icon6.png",
      title: "Vigilant handles client support",
    },
  ]

  const mediaCardR3 = [
    {
      rec: "../images/client-support/icon7.png",
      title: "No Competitive Undercutting",
    },
    {
      rec: "../images/client-support/icon8.png",
      title: "Growth Industry",
    },
    {
      rec: "../images/client-support/icon9.png",
      title: "On-going Sales Training Mentorship",
    },
  ]

  function submit(e) {
    e.preventDefault()
    const openErrors = errorFindSTA(fields)
    if (Object.keys(openErrors).length > 0) {
      setErrors(openErrors)
    } else {
      console.log("hi")
      var xhr = new XMLHttpRequest()
      var url =
        "https://api.hsforms.com/submissions/v3/integration/submit/7763866/964fc9ee-49f9-4ffb-9660-03c52ac3c893"

      var data = {
        fields: [
          {
            name: "firstname",
            value: fields.firstName,
          },
          {
            name: "lastname",
            value: fields.lastName,
          },
          {
            name: "jobtitle",
            value: fields.job,
          },
          {
            name: "company",
            value: fields.company,
          },
          {
            name: "email",
            value: fields.email,
          },
          {
            name: "subscribe",
            value: subscribe ? true : false,
          },
        ],
      }

      var final_data = JSON.stringify(data)

      xhr.open("POST", url)
      // Sets the value of the 'Content-Type' HTTP request headers to 'application/json'
      xhr.setRequestHeader("Content-Type", "application/json")

      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4 && xhr.status === 200) {
          window.location.href = "/us/gated-asset-form-success"
        } else if (xhr.readyState === 4 && xhr.status === 400) {
          console.log(xhr.responseText) // Returns a 400 error the submission is rejected.
        } else if (xhr.readyState === 4 && xhr.status === 403) {
          console.log(xhr.responseText) // Returns a 403 error if the portal isn't allowed to post submissions.
        } else if (xhr.readyState === 4 && xhr.status === 404) {
          console.log(xhr.responseText) //Returns a 404 error if the formGuid isn't found
        }
      }

      // Sends the request

      xhr.send(final_data)
    }
  }

  return (
    <Layout>
      <Seo title="VIGILANT CHANNEL PARTNERS" />
      <div className="breadcrumbs">
        <nav className="container-fluid p-sides-med">
          <ul>
            {breadData.map(breadcrumb => (
              <li key={breadcrumb.id}>
                <Link to={breadcrumb.ink}>{breadcrumb.label}</Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>
      <section className="section-title-top--with-form grid--bg --for-it-ops-2">
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <h1>
                VIGILANT <br /> CHANNEL PARTNERS
              </h1>
              <h4 style={{ color: "#3EB3E3" }}>INVEST IN YOUR FUTURE</h4>
              <p className="content">
                Partnerships are marked by developmental relationships <br />
                and an investment mindset.
              </p>
              <div className="text-left">
                <a
                  href="https://partners.vigilantnow.com/"
                  className="nav-section--cta-btn"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="d-flex align-items-center">
                    <span
                      style={{ marginLeft: "25px" }}
                      className="nav-section--cta-title pr-5"
                    >
                      partner portal login
                    </span>
                    <Dar
                      className="chevy"
                      sx={{ fontSize: 15, marginLeft: 2 }}
                    />
                  </div>
                </a>
              </div>
            </div>

            <div className="col-md-5 pt-5">
              <div className="box">
                <Form>
                  <Form.Group className="mb-3" controlId="formFirstName">
                    <Form.Control
                      value={fields.firstName}
                      className="bg-white"
                      type="fName"
                      placeholder="First Name"
                      onChange={e =>
                        setFields({ ...fields, firstName: e.target.value })
                      }
                    />

                    <span>{errors.firstName}</span>
                  </Form.Group>
                  <FormGroup className="mb-3" controlId="formLastName">
                    <FormControl
                      value={fields.lastName}
                      className="bg-white"
                      type="lName"
                      placeholder="Last Name"
                      onChange={e =>
                        setFields({ ...fields, lastName: e.target.value })
                      }
                    />
                    <span type="invalid">{errors.lastName}</span>
                  </FormGroup>
                  <Form.Group className="mb-3" controlId="formJobTitle">
                    <Form.Control
                      value={fields.job}
                      className="bg-white"
                      type="job"
                      placeholder="Job Title"
                      onChange={e =>
                        setFields({ ...fields, job: e.target.value })
                      }
                    />

                    <span>{errors.job}</span>
                  </Form.Group>
                  <FormGroup className="mb-3" controlId="formCompany">
                    <FormControl
                      value={fields.company}
                      className="bg-white"
                      type="jobTitle"
                      placeholder="Company"
                      onChange={e =>
                        setFields({ ...fields, company: e.target.value })
                      }
                    />
                    <span type="invalid">{errors.company}</span>
                  </FormGroup>
                  <FormGroup className="mb-3" controlId="formBasicEmail">
                    <FormControl
                      value={fields.email}
                      className="bg-white"
                      type="email"
                      placeholder="Business Email"
                      onChange={e =>
                        setFields({ ...fields, email: e.target.value })
                      }
                    />
                    <span type="invalid">{errors.email}</span>
                  </FormGroup>
                  <FormGroup>
                    <Form.Check
                      id="custom-switch"
                      label="Sign up to receive updates from Vigilant about our services, news and events. You can unsubscribe anytime."
                      onClick={() => setSubscribe(!subscribe)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Button
                      style={{
                        width: "100%",
                        backgroundColor: "transparent",
                      }}
                      className="nav-section--cta-btn"
                      variant="primary"
                      type="submit"
                      onClick={submit}
                    >
                      <h4>APPLY TO PARTNER PROGRAM</h4>
                    </Button>
                    <p className="pt-5">
                      <em>
                        Vigilant is committed to ensuring the security and
                        protection of the personal information that we process,
                        and to provide a compliant and consistent approach to
                        data protection. If you have any questions related to
                        our privacy policies, please contact
                        legal@vigilantnow.com
                      </em>
                    </p>
                  </FormGroup>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div>
        <section className="section-partners">
          <div className="container">
            <div className="row">
              <div className="section-col-1">
                <h3>Developmental Relationship and Investment Mindset </h3>
                <hr />
              </div>
            </div>
            <div className="row">
              <div className="section-col-2">
                <p>
                  Partnerships share fortunes, futures and success. That’s why
                  Vigilant has designed a developmental experience that is
                  unique in the sales space. We maintain an exclusive network of
                  partners by investing in their growth through many levels of
                  support including monthly touchpoints, training sessions and
                  incentives. From Owners to Sales Teams to Sales Engineers, we
                  fight for your future.
                </p>
                <p>
                  Partnering with Vigilant is not a transaction, it is an
                  investment and just like purchasing a professional annuity,
                  your returns grow over time. When you supply your clients with
                  Vigilant’s service and technologies, you will be securing a
                  predictable and often growing income stream.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div>
        <section className="section-box--partners">
          <div className="container">
            <div className="section-box-content p-5">
              <div className="row p-3">
                {mediaCardR1.map(data => {
                  return (
                    <MediaCard
                      imgSrc={data.rec}
                      title={data.title}
                      altTxt={data.title}
                      key={uuidv4()}
                    />
                  )
                })}
              </div>
              <div className="row p-3">
                {mediaCardR2.map(data => {
                  return (
                    <MediaCard
                      imgSrc={data.rec}
                      title={data.title}
                      altTxt={data.title}
                      key={uuidv4()}
                    />
                  )
                })}
              </div>
              <div className="row p-3">
                {mediaCardR3.map(data => {
                  return (
                    <MediaCard
                      imgSrc={data.rec}
                      title={data.title}
                      altTxt={data.title}
                      key={uuidv4()}
                    />
                  )
                })}
              </div>
            </div>
          </div>
        </section>
      </div>
      <div>
        <section className="section-box-cta">
          <div className="container">
            <div className="section-col-1">
              <div className="section-col-1--content">
                <h2>
                  Vigilant’s sales team exists to support <br />
                  our Partners, not compete with them.
                </h2>
                <hr style={{ width: "auto" }} />
                <a
                  href="https://sp1.sdcdn.app/temp/Vigilant_PartnerProgram_TwoPager.pdf"
                  className="nav-section--cta-btn"
                  target="__blank"
                >
                  <div className="d-flex align-items-center">
                    <span
                      style={{ marginLeft: "25px" }}
                      className="nav-section--cta-title pr-5"
                    >
                      DOWNLOAD PARTNER PROGRAM BRIEF
                    </span>
                    <Dar
                      className="chevy"
                      sx={{ fontSize: 15, marginLeft: 2 }}
                    />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="breadcrumbs">
        <nav className="container-fluid p-sides-med">
          <ul>
            {breadData.map(breadcrumb => (
              <li key={breadcrumb.id}>
                <Link to={breadcrumb.ink}>{breadcrumb.label}</Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </Layout>
  )
}

export default PartnersChan
